var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    [
      _c(
        "v-chip-group",
        {
          attrs: {
            mandatory: "",
            "show-arrows": "",
            "active-class": "primary--text",
          },
          model: {
            value: _vm.selectedCat,
            callback: function ($$v) {
              _vm.selectedCat = $$v
            },
            expression: "selectedCat",
          },
        },
        _vm._l(_vm.categories, function (cat) {
          return _c("v-chip", { key: cat.id }, [
            _vm._v(" " + _vm._s(cat.value) + " "),
          ])
        }),
        1
      ),
      _vm.items && _vm.items.length > 0
        ? _c(
            "v-row",
            { staticClass: "mt-5" },
            _vm._l(_vm.items, function (item, i) {
              return _c(
                "v-col",
                {
                  key: i,
                  staticClass: "cursor",
                  attrs: { cols: "12", sm: "6" },
                },
                [
                  _c(
                    "v-card",
                    {
                      staticClass: "ma-4",
                      attrs: {
                        elevation: "0",
                        to: `/c/contents/podcast/${item.id}`,
                      },
                    },
                    [
                      _c(
                        "v-row",
                        {
                          staticClass: "fill-height",
                          attrs: { align: "center", justify: "center" },
                        },
                        [
                          _c(
                            "v-scale-transition",
                            [
                              _c(
                                "v-card",
                                {
                                  staticClass:
                                    "pa-4 d-flex flex-sm-row text-sm-right",
                                },
                                [
                                  _c(
                                    "v-card",
                                    { attrs: { flat: "" } },
                                    [
                                      _c(
                                        "v-avatar",
                                        { attrs: { size: "95", rounded: "" } },
                                        [
                                          _c("v-img", {
                                            attrs: {
                                              "lazy-src": require("@/assets/images/placeholder.png"),
                                              src:
                                                item.picture != null
                                                  ? item.picture
                                                  : require("@/assets/images/placeholder.png"),
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "d-flex flex-column mx-2" },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "max-2-line",
                                          staticStyle: {
                                            "max-height": "60px",
                                            "max-width": "120px",
                                          },
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "f14 font-weight-bold",
                                            },
                                            [_vm._v(" " + _vm._s(item.title))]
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "f14 font-weight-bold text-blue",
                                          on: {
                                            click: function ($event) {
                                              $event.preventDefault()
                                              return _vm.openDrBottomSheet(
                                                item.publisher
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v(_vm._s(item.publisherName))]
                                      ),
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "f13 font-weight-light mt-1",
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm._f("moment")(
                                                  item.createDate,
                                                  "from",
                                                  "now"
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            }),
            1
          )
        : _c("span", { staticClass: "text-center f16 d-block mt-8" }, [
            _vm._v(" " + _vm._s(_vm.$t("No results found")) + " "),
          ]),
      this.selectedCat != 0
        ? _c(
            "infinite-loading",
            {
              attrs: { identifier: _vm.selectedCat },
              on: { infinite: _vm.infiniteHandler },
            },
            [
              _c(
                "div",
                { attrs: { slot: "spinner" }, slot: "spinner" },
                [_c("v-icon", [_vm._v("mdi-selection-ellipse spin")])],
                1
              ),
              _c(
                "div",
                { attrs: { slot: "no-more" }, slot: "no-more" },
                [_c("v-icon", [_vm._v("mdi-access-point-off")])],
                1
              ),
              _c(
                "div",
                { attrs: { slot: "no-results" }, slot: "no-results" },
                [_c("v-icon", [_vm._v("mdi-access-point-off")])],
                1
              ),
            ]
          )
        : _vm._e(),
      _c("BshDoctor", { ref: "drBottomSheet" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }