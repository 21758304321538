<template>
  <section>
    <v-chip-group
      mandatory
      show-arrows
      active-class="primary--text"
      v-model="selectedCat"
    >
      <v-chip v-for="cat in categories" :key="cat.id">
        {{ cat.value }}
      </v-chip>
    </v-chip-group>
    <v-row class="mt-5" v-if="items && items.length > 0">
      <v-col v-for="(item, i) in items" :key="i" cols="12" sm="6"  class="cursor">
        <v-card class="ma-4" elevation="0" :to="`/c/contents/podcast/${item.id}`">
          <v-row class="fill-height" align="center" justify="center">
            <v-scale-transition>
              <v-card class="pa-4 d-flex flex-sm-row text-sm-right">
                <v-card flat>
                  <v-avatar size="95" rounded class="">
                    <v-img
                      :lazy-src="require('@/assets/images/placeholder.png')"
                      :src="
                        item.picture != null
                          ? item.picture
                          : require('@/assets/images/placeholder.png')
                      "
                    ></v-img>
                  </v-avatar>
                </v-card>
                <div class="d-flex flex-column mx-2">
                  <div
                    style="max-height: 60px; max-width: 120px"
                    class="max-2-line"
                  >
                    <span class="f14 font-weight-bold"> {{ item.title }}</span>
                  </div>
                  <span class="f14 font-weight-bold text-blue"  @click.prevent="openDrBottomSheet(item.publisher)">{{
                    item.publisherName
                  }}</span>
                  <span class="f13 font-weight-light mt-1">
                    {{ item.createDate | moment("from", "now") }}
                  </span>
                </div>
              </v-card>
            </v-scale-transition>
          </v-row>
        </v-card>
      </v-col>
    </v-row>

    <span class="text-center f16 d-block mt-8" v-else>
      {{$t('No results found')}}
    </span>
    
    <infinite-loading
      v-if="this.selectedCat != 0"
      @infinite="infiniteHandler"
      :identifier="selectedCat"
    >
      <div slot="spinner">
        <!-- {{ $t("Loading...") }} -->
        <v-icon>mdi-selection-ellipse spin</v-icon>
      </div>
      <div slot="no-more">
        <!-- {{ $t("No more items") }} -->
        <v-icon>mdi-access-point-off</v-icon>
      </div>
      <div slot="no-results">
        <!-- {{ $t("No results found") }} -->
        <v-icon>mdi-access-point-off</v-icon>
      </div>
    </infinite-loading>

    <BshDoctor ref="drBottomSheet" />
  </section>
</template>
<script>
import BshDoctor from "@/components/DoctorProfile.vue";
import ApiService from "@/http/ApiService";
import moment from "moment-jalaali";
import fa from "moment/locale/fa";
moment.locale("fa", fa);
moment.loadPersian();
export default {
  components: {
     BshDoctor
  },
  data() {
    return {
      categories: [],
      selectedCat: 0,

      items: [],
      page: 1,
      size: 12,
    };
  },
  methods: {
    openDrBottomSheet(mobile){
      this.$refs.drBottomSheet.openWithDoctorMobile(mobile);
    },
    getAllCategories() {
      ApiService.getActivities()
        .then((response) => {
          this.categories = response.data;
          var first = {
            id: -1,
            value: "همه",
          };

          this.categories.unshift(first);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getPodcastspublishPaging(page, size) {
      ApiService.getPodcastspublishPaging(page, size)
        .then((response) => {
          this.items = response.data.data;
          
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getPodcastsByGroupId(id) {
      ApiService.getPodcastsByGroupId(id).then((response) => {
        this.items = [];
        this.items = response.data;
      });
    },
    infiniteHandler($state) {
      if (this.selectedCat != 0) {
        ApiService.getPodcastspublishPaging(this.page, this.size)
          .then((response) => {
            if (response.data.length) {
              response.data.forEach((element) => {
                console.log(element);
                this.items.push(element);
              });

              this.page += 1;
              $state.loaded();
            } else {
              $state.complete();
            }
          })
          .catch((err) => {
            console.log(err);
            $state.error();
          });
      }
    },
  },
  beforeMount() {
    this.$store.commit('update_backActive', true);
    this.$store.commit('update_helloActive', false);
    this.$store.commit('update_threeDotsActive', false);
    this.$store.commit('update_notificationActive', true);
    this.$store.commit('update_toolbarTitleActive', true);
    
    this.getAllCategories();
    this.getPodcastspublishPaging(0, this.size);
  },
  watch: {
    selectedCat: function () {
      if (this.selectedCat == 0) {
        this.getPodcastspublishPaging(0, this.size);
      } else {
        this.getPodcastsByGroupId(this.categories[this.selectedCat].id);
      }
    },
  },
};
</script>